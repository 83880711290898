import React, { useState } from 'react';
import { Pressable } from 'react-native';
import { useQuery } from '@apollo/client';

import DataState from 'app/src/elements/DataState';
import { Warning, WarningText } from 'app/src/styles';

import Tag from './show';
import { TAG } from './queries';

const VoidableError = ({ error, onVoidCompleted }) => {
  const [showVoid, setShowVoid] = useState();

  const { data } = useQuery(TAG, { variables: { id: error.path[2] } });

  if (!data) { return null; }

  return (
    <>
      <WarningText>Void the previous pre-registration to continue.</WarningText>

      {data.tag.canVoid && (
        <Pressable onPress={() => setShowVoid(data.tag)}>
          <WarningText>
            Click here to review prior pre-registration.
          </WarningText>
        </Pressable>
      )}

      {showVoid && (
        <Tag
          tag={showVoid}
          setShow={setShowVoid}
          refetch={onVoidCompleted}
          voidTag
        />
      )}
    </>
  );
};

const PreRegistrationErrors = ({ errors, onVoidCompleted }) => {
  return (
    <Warning>
      {errors.map(error => (
        <React.Fragment key={error}>
          <WarningText>{error.message}</WarningText>

          {'duplicate' === error.path[0] && (
            <VoidableError error={error} onVoidCompleted={onVoidCompleted} />
          )}
        </React.Fragment>
      ))}
    </Warning>
  );
};

export default PreRegistrationErrors;
